@import "~@fortawesome/fontawesome-free/css/all.css";

/* Head Section */
.Head {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  height: 50px;
  left: 50%;
  zoom: 120%;
}

.HeadLogo {
  color: #0095ff;
  font-size: 2rem;
  cursor: pointer;
  border-color: #3a00f9;
}

.HeadNumber {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: none;
}

/* Navbar Section */
.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  width: 95%;
  height: 100px;
  position: absolute;
  z-index: 999;
}

.navbar-logo {
  color: #39baff;
  font-size: 0.5rem;
  cursor: pointer;
  padding-top: 100px;
  padding-right: 0px;
}

.navbar-logo img {
  height: 23%;
  width: 23%;
  margin-left: -220px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  color: #0095ff;
}

.nav-links {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  cursor: pointer;
}

.nav-links i {
  padding-right: 10px;
  color: #39baff;
}

.nav-links:hover {
  background-color: #ffffff;
  color: #0095ff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.nav-links-mobile {
  display: none;
}

button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: white;
}

.button:hover {
  background-color: #151516;
  color: #fff;
}

.menu-icons {
  display: none;
}

/* Mobile and Responsive Design */
@media screen and (max-width: 420px) {
  .Head {
    padding: 0 8px;
    align-items: center;
    height: 50px;
  }

  .HeadLogo {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .navbar-logo {
    padding-right: 20px;
    padding-top: 20px;
    z-index: 1000;
  }

  .navbar-logo img {
    height: 53%;
    width: 53%;
    margin-left: -100px;
  }

  .NavbarItems {
    z-index: 999;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #44769a;
    width: 70%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0%;
    opacity: 0;
    align-items: stretch;
    padding: 95px 10px 10px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .nav-menu.active {
    left: 1;
    opacity: 1;
    z-index: 999;
    color: #0095ff;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.7rem;
    padding: 1rem 0;
  }

  .nav-links:hover {
    background: #000000;
    transition: none;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 2rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background: #ffffff;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .menu-icons {
    display: block;
    color: #0095ff;
    cursor: pointer;
    z-index: 9999;
  }

  .menu-icons i {
    font-size: 3rem;
    color: #0095ff;
    padding-left: 70px;
  }
}

.selected-section-title {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 420px) and (max-width: 750px) {
  .Head {
    padding: 0 8px;
    align-items: center;
  }

  .NavbarItems {
    z-index: 999;
  }

  .HeadLogo {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navbar-logo {
    padding-right: 20px;
    padding-top: 100px;
  }

  .navbar-logo img {
    height: 50%;
    width: 60%;
    margin-left: -100px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #4c7a9b;
    width: 100%;
    height: auto;
    position: absolute;
    top: 20%;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 130px 80px 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
    border-radius: 13px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    color: white;
  }

  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.7rem;
    padding: 2rem 0;
  }

  .nav-links:hover {
    background: #000000;
    transition: none;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 2rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background: #ffffff;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
  }

  .menu-icons {
    display: block;
    cursor: pointer;
  }

  .menu-icons i {
    font-size: 4rem;
    color: #39baff;
    padding-left: 70px;
    padding-top: 100px;
  }
}
