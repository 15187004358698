/* FAQ.css */

.faq-container {
  max-width: 600px;
  margin: 0 auto;
}

.faq-item {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item:hover {
  background-color: #354c4c;
}

.faq-item.active {
  background-color: #3b3f43;
}

.question {
  font-weight: bold;
  font-size: 1.3rem;
  color: #0095ff;
}

.answer {
  display: none;
  margin-top: 10px;
  color: white;
  font-size: 1.3rem;
}

.faq-item.active .answer {
  display: block;
}

.triangle {
  color: #ffffff;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ffffff; /* Adjust the color as needed */
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  transition: transform 0.3s ease; /* Smooth rotation animation */
}

.triangle.open {
  transform: rotate(90deg); /* Rotate the triangle when the question is open */
}
