.reviews-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.reviews-container h2 {
  padding-top: 30px;
  font-size: 20px;
  color: #0095ff;
}

.title p {
  color: #fff;
}

.review {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 40px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 15px;
}
.review-profile img {
  width: 80%;
  height: 80%;
  border-radius: 50%;
}

.review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: auto;
}

.review-city {
  color: #8d8585;
  margin-left: 10px;
}

.review-date {
  color: #353535;
  margin-top: 5px;
}

.review-comment {
  margin-top: 10px;
  color: #ffff;
  align-items: center;
}

.zoomIn {
  transform: translateY(0) scale(1);
  animation: zoomIn 2.5s ease-in-out;
  opacity: 1;
}

@keyframes zoomIn {
  0% {
    transform: translateY(1%) scale(0.95);
    opacity: 0;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}
.zoomOut {
  transform: translateY(1%) scale(0.95);
  animation: zoomOut 3s ease-in-out;
  opacity: 0;
}

@keyframes zoomOut {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateY(1%) scale(0.95);
    opacity: 0;
  }
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding-left: 70px;
  padding-right: 70px;
  border-radius: 15px;
}

.review-rating {
  color: gold;
}

.star {
  font-size: 2.7rem;
  color: rgb(255, 221, 0);
  margin-left: 10px;
}

.review-details {
  display: flex;
  flex-direction: column;
  color: #4b92c5;
  align-items: center;
}

.review-details p {
  color: white;
  margin-top: 50px;
}
.review-details h1 {
  font-size: 15px;
}

.review h2 {
  margin: 0;
  color: #4b92c5;
  align-items: center;
}

.review p {
  color: #ffffff;
  align-items: center;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-separator {
  margin: 0 1rem;
  color: #ccc;
  font-size: 1.2rem;
}

.link-style {
  text-decoration: none; /* Remove underline */
  color: #0095ff;
  align-items: center;
  margin-left: auto;
  font-size: 25px;
}

.link-style:hover {
  color: rgb(203, 237, 247);
  font-size: 26px;
  transition: font-size 0.2s, color 0.2s;
}

.revealLeft {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2s;
  overflow-x: hidden;
}

.hideLeft {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s;
}
/* Reviews.css */
.pagination-status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(255, 255, 255);
}

.circle.filled {
  background-color: rgb(255, 0, 0);
}

@media (max-width: 850px) {
  .title h1 {
    color: #0095ff;
    font-size: 27px;
  }

  .title p {
    color: #fff;
    font-size: 20px;
  }

  .reviews-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: -50px;
    margin-right: -50px;
  }

  .pagination {
    margin-top: 3rem;
    margin-left: 1.3rem;
    margin-right: 1.3rem;
  }

  .review {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .review-content {
    display: flex;
    flex-direction: row;
    margin-left: 25px;
    /* Align profile and header horizontally */
  }

  .review-profile img {
    margin-top: 25px;
    width: 120px;
    height: 120px; /* Add spacing between profile and header */
  }

  .review-details {
    margin-top: 15px;
    font-size: 16px;
  }

  .review-header {
    flex: 1;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
  .review-details p {
    color: white;
    margin-top: 10px;
  }
}
