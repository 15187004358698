.title {
  font-size: 2rem;
  color: #0095ff;
  padding-top: 20px;
  background-color: #2d2d2f;
  padding-bottom: 20px;
  border-color: rgb(0, 0, 0);
  margin-top: 30px;
  border: 1px solid #39baff;
}

.title p {
  color: #ffff;
}

.services {
  margin: 1.5rem 2rem;
  background-color: #2d2d2f;
  padding: 10px 20px 5px 20px;
  border-radius: 13px;
  z-index: 9999999;
  border: 0.1px lightblue solid;
}

.revealLeft {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2s;
  overflow-x: hidden;
}

.hideLeft {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s;
}

.revealRight {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2s;
  overflow-x: hidden;
}

.hideRight {
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s;
}

.services h1 {
  font-size: 2rem;
  color: #4b92c5;
}

.services h2 {
  color: #0095ff;
  padding-bottom: 20px;
  font-size: 1.5rem;
  margin-left: -25px;
}
.services p {
  color: #ffffff;
}

.first-serv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.5rem;
}

.serv-text {
  width: 50%;
  text-align: start;
  font-size: 1.5rem;
  color: #4b92c5;
  padding-left: 20px;
}

.serv-text h2 {
  padding-bottom: 1rem;
  color: #0095ff;
  text-align: center;
  width: 100%;
  font-size: 2.5rem;
}

.serv-text p {
  color: rgb(255, 255, 255);
  padding-right: 10px;
}

.image {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.image img {
  width: 49%;
  height: 250px;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

.zoomInLeft {
  transform: translateX(0) scale(1);
  animation: zoomInLeft 2.5s ease-in-out;
  opacity: 1;
  overflow-x: hidden;
}

@keyframes zoomInLeft {
  0% {
    transform: translateX(-20%) scale(0.95);
    opacity: 0;
  }

  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}
.zoomOutLeft {
  transform: translateX(-20%) scale(0.95);
  animation: zoomOutLeft 3s ease-in-out;
  opacity: 0;
  overflow-x: hidden;
}

@keyframes zoomOutLeft {
  0% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-20%) scale(0.95);
    opacity: 0;
  }
}

.zoomInRight {
  transform: translateX(0) scale(1);
  animation: zoomInRight 2.5s ease-in-out;
  opacity: 1;
  overflow-x: hidden;
}

@keyframes zoomInRight {
  0% {
    transform: translateX(20%) scale(0.95);
    opacity: 0;
    overflow-x: hidden;
  }

  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
    overflow-x: hidden;
  }
}
.zoomOutRight {
  transform: translateX(20%) scale(0.95);
  animation: zoomOutRight 3s ease-in-out;
  opacity: 0;
  overflow-x: hidden;
}

@keyframes zoomOutRight {
  0% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(20%) scale(0.95);
    opacity: 0;
  }
}

@media screen and (max-width: 850px) {
  .title {
    font-size: 1rem;
    border-radius: 30px;
    color: #4b92c5;
  }

  .services {
    margin: 4rem 2rem;
  }

  .first-serv {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
  }

  .serv-text {
    width: 100%;
  }

  .serv-text h2 {
    width: 150%;
    font-size: 2.1rem;
    padding-right: 12.5rem;
    padding-left: -2.5rem;
  }

  .image {
    width: 100%;
    margin: 1.8rem 0;
  }

  .image img {
    height: 250px;
  }
}
