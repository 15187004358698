::-webkit-scrollbar {
  width: 0rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.galleryContainer {
  padding: 3rem 1rem;
  margin: 15px;
  scroll-behavior: smooth;
  zoom: 80%;
  font-size: 62.5%;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 50rem;
  padding: 2rem 0rem;
  position: relative;
  margin-left: 50px;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}
.swiper-slide img {
  border-color: #0095ff;
}

.galleryButton {
  color: #0095ff;
  background-color: #324044;
  align-self: center;
  margin-left: 0px;
  margin-top: 50px;
  font-size: 20px;
  border-radius: 20px;
}

.galleryButton:hover {
  background-color: #768f9f7f;
}

@media screen and (max-width: 850px) {
  .button {
    align-self: center;
    margin-left: 30px;
    margin-top: 20px;
    font-size: 20px;
  }
}

@media (max-width: 3000px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 35rem;
  height: 35rem;
  border-radius: 3rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  align-self: center;
  right: -12.5px;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: #676767;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 52%;
  left: 40%;
  transform: translateX(-52%);
  filter: drop-shadow(0px 8px 24px rgba(0, 140, 255, 0.268));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 3rem;
  color: #0095ff;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 5rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(255, 0, 0, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
  color: #ff0000;
}

.revealLeft {
  opacity: 1;
  transform: translateX(0%);
  transition: all 2s;
  overflow-x: hidden;
}

.hideLeft {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 2s;
}

.zoom-in {
  transform: scale(1);
  animation: zoom-in 2s ease-in-out;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
.zoom-out {
  transform: scale(0.5);
  animation: zoom-out 2s ease-in-out;
  opacity: 0;
}

@keyframes zoom-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}
