/* Locations.css */
@import "~@fortawesome/fontawesome-free/css/all.css";
/* Style for the main container */
.locations-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #000000;
}

/* Style for the rows */
.locations-row {
  display: flex; /* Use flexbox for the rows */
  justify-content: space-between; /* Space columns evenly in each row */
  margin-bottom: 20px; /* Add margin between rows */
}

/* Style for the columns */
.locations-column {
  flex: 1; /* Make columns flexible to grow equally */
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #000000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  margin: 0 10px; /* Add margin between columns */
}

/* Style for the category headings */
.locations-category {
  font-weight: bold;
}

/* Style for the city lists */
.locations-list {
  list-style-type: none;
  margin-left: 0px;
  color: white;
}

/* Style for the city links */
.locations-list a {
  text-decoration: none; /* Remove underline */
  color: rgb(45, 121, 220); /* Set the link color to white */
}

/* Style for individual cities */
.locations-list li {
  margin: 10px 0;
  cursor: pointer;
  transition: font-size 0.5s, color 0.5s;
  font-size: 10px;
}

.locations-list h1 {
  text-decoration: none;
}

.locations-list li:hover {
  font-size: 20px;
  color: #4b92c5;
}

/* Media query for smaller screens (e.g., mobile) */
@media (max-width: 850px) {
  /* Adjust styles for smaller screens here */
  .locations-container {
    flex-direction: column; /* Stack columns on top of each other */
  }
  .locations-column {
    flex-basis: 100%; /* Make columns 100% wide on smaller screens */
  }
}

.head {
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  height: 50px;
  left: 50%;
  zoom: 120%;
  overflow-x: hidden;
}

.headLogo {
  color: #0095ff;
  font-size: 2rem;
  cursor: pointer;
  border-color: #3a00f9;
  margin-top: 0px;
  text-decoration: none;
}
.headNumber {
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
}

/* Base styles for larger screens */
.background-Main {
  height: auto;
  background-color: #000000;
  overflow-x: hidden;
}

.navbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  position: absolute;
  z-index: 999;
  border-radius: 25px;
  margin-top: -480px;
}

.countinerStyle {
  height: 50vh;
  position: relative;
  border-radius: 25px;
}
.navbarLogo {
  color: #39baff;
  font-size: 1rem;
  cursor: pointer;
  padding-right: 0px;
  z-index: 999;
}

.navbarLogo img {
  height: 40%;
  width: 25%;
  margin-left: -500px;
  z-index: 999;
  margin-top: 50px;
}

.navMenu {
  text-decoration: none;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  color: #0095ff;
}

.navLinks {
  text-decoration: none;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
  cursor: pointer;
  z-index: 999;
}

.navLinks i {
  text-decoration: none;
  color: #39baff;
}

.icon-with-text {
  display: flex;
  align-items: center;
  color: rgb(45, 121, 220);
}

.icon-with-text i {
  margin-right: 15px;
}

.navLinks:hover {
  background-color: #ffffff;
  color: #0095ff;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
}

.button {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: white;
}

.button:hover {
  background-color: #151516;
  color: #fff;
}

.content-container {
  display: flex;
  align-items: flex-start;
  margin: 20px;
  margin-top: 70px;
  margin-left: 100px;
  margin-right: 100px;
  border-radius: 25px;
}

.imageContainer {
  color: white;
  flex: 1;
  padding: 20px;
  margin-left: 40px;
  border-radius: 25px;
}

.imageContainer p {
  font-size: 20px;
}

.imageContainer img {
  width: 40%;
  height: 20%;
  border-radius: 25px;
}

.text-container {
  color: #fff;
  flex: 2;
  padding: 20px;
  background-color: #0095ff35;
  border-radius: 25px;
  margin-left: 50px;
}

.paragraph {
  margin-bottom: 20px;
}

.bottom-paragraph {
  margin: 20px;
  color: #fff;
  background-color: #0095ff35;
  border-radius: 25px;
}

.Services {
  margin: 1.5rem 2rem;
  background-color: #000000;
  padding: 10px 20px 5px 20px;
  border-radius: 13px;
  z-index: 9999999;
  border: 0.1px rgb(0, 0, 0) solid;
}

.firstServ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.servText {
  width: 50%;
  text-align: start;
  font-size: 1.5rem;
  color: #4b92c5;
  padding-left: 20px;
}

.servText h2 {
  padding-bottom: 2rem;
  color: #0095ff;
  width: 98%;
  font-size: 2.5rem;
}

.servText p {
  color: rgb(255, 255, 255);
  padding-right: 10px;
}

/* Media query for smaller screens (e.g., mobile) */
@media screen and (min-width: 390px) and (max-width: 850px) {
  .background-Main {
    height: auto;
  }

  .head {
    padding: 0 8px;
    align-items: center;
  }

  .headLogo {
    font-size: 0.9rem;
    cursor: pointer;
    text-decoration: none;
  }
  .headNumber {
    font-size: 1.3rem;
  }

  .navbarItems {
    margin-bottom: 0px;
    height: 0px;
    margin-top: -375px;
    padding: 15px 15px 15px 15px;
  }

  .navbarLogo {
    font-size: 0rem;
    cursor: pointer;
    margin-top: 0px;
  }
  .navbarLogo img {
    height: 10%;
    width: 40%;
    margin-left: -130px;
  }

  .navLinks {
    font-size: 1.5rem;
    margin-right: -15px;
    border-radius: 25px;
    text-align: center;
    margin-top: 55px;
  }

  .navLinks i {
    text-decoration: none;
    color: #39baff;
    text-align: center;
    padding-right: 5px;
  }

  .icon-with-text i {
    margin-right: 0px;
    text-align: center;
    width: auto;
  }

  .icon-with-text h1 {
    text-align: center;
    width: 35px;
  }

  .content-container {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }

  .imageContainer {
    margin-left: 0;
    font-size: 15px;
  }

  .imageContainer img {
    width: 70%;
    height: 70%;
    border-radius: 25px;
  }

  .text-container {
    margin-left: 0px;
  }

  .Services {
    margin: 4rem 2rem;
    width: 92%;
  }

  .firstServ {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .servText {
    width: 100%;
    text-align: center;
  }

  .servText h2 {
    width: 100%;
    font-size: 2.1rem;

    text-align: center;
  }
}

@media screen and (min-width: 850px) and (max-width: 1580px) {
  .navbarItems {
    margin-bottom: 0px;
    height: 0px;
    margin-top: -275px;
    padding: 15px 15px 15px 15px;
  }

  .navbarLogo {
    font-size: 0rem;
    cursor: pointer;
    margin-top: 0px;
  }
  .navbarLogo img {
    height: 10%;
    width: 20%;
    margin-left: -430px;
  }

  .navLinks {
    font-size: 1.5rem;
    margin-right: 15px;
    border-radius: 25px;
    text-align: center;
    margin-top: 55px;
  }

  .navLinks i {
    text-decoration: none;
    color: #39baff;
    text-align: center;
    padding-right: 5px;
  }

  .icon-with-text i {
    margin-right: 0px;
    text-align: center;
    width: auto;
  }

  .icon-with-text h1 {
    text-align: center;
    width: 35px;
  }
}
