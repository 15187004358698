.about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #000000;
  padding-right: 20px;
  padding-left: 30px;
  padding-top: 25px;
  overflow: hidden;
}

.about-content {
  display: flex;
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  background-color: #646669b7;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
}

.about-text {
  flex: 1;
  padding-right: 20px;
  color: #ffffff;
  text-align: start;
}

.about-text h1 {
  color: #0095ff;
  text-align: center;
  font-size: 2rem;
}
.about-text p {
  font-display: flex;
  font-size: 12px;
}

.about-photo {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 30px;
  padding-right: 20px;
}

.about-photo img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.revealRight {
  opacity: 1;
  transform: translateX(0%);
  transition: all 1s;
  overflow-x: hidden;
}

.hideRight {
  opacity: 0;
  transform: translateX(100%);
  overflow-x: hidden;
}

.revealLeft {
  opacity: 1;
  transform: translateX(0%);
  transition: all 1s;
  overflow-x: hidden;
}

.hideLeft {
  opacity: 0;
  transform: translateX(-100%);
  overflow-x: hidden;
}

@media screen and (max-width: 750px) {
  .about {
    flex-direction: column-reverse;
    height: auto;
    padding: 20px;
  }

  .about-content {
    width: auto;
    flex-direction: column;
  }

  .about-text,
  .about-photo {
    flex: 1;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .about-photo {
    display: flex;
    justify-content: center;
  }

  .about-photo img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .title {
    font-size: 1.5rem;
    padding: 10px;
    margin-top: 20px;
  }

  .about-text h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .about-text p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .about-content {
    background-color: #646669b7;
    padding: 20px;
  }
}
